import { Phone, List, MessageCircle, Mail, FileText } from 'react-feather';
import { CLIENT_INTERACTIONS_TYPES, COMMUNICATION_TYPES } from 'core/utils/constants';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCommunication, visitInteraction } from 'redux/ui/clientInteractionDrawer/reducer';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${props => (props.visited ? 'var(--magenta_primary)' : 'var(--blue_6)')};
`;

const typeToIcon = {
  [COMMUNICATION_TYPES.TEXT]: MessageCircle,
  [COMMUNICATION_TYPES.PHONE_CALL]: Phone,
  [COMMUNICATION_TYPES.CUSTOM]: List,
  [CLIENT_INTERACTIONS_TYPES.TICKET]: FileText,
  [CLIENT_INTERACTIONS_TYPES.EMAIL]: Mail,
  [CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN]: List,
  [CLIENT_INTERACTIONS_TYPES.CHAT]: MessageCircle,
  empty: null
};

export const typeToRoute = {
  [CLIENT_INTERACTIONS_TYPES.CHAT]: 'text-communications',
  [CLIENT_INTERACTIONS_TYPES.TICKET]: 'text-communications',
  [CLIENT_INTERACTIONS_TYPES.EMAIL]: 'text-communications',
  [COMMUNICATION_TYPES.TEXT]: 'text-communications',
  [COMMUNICATION_TYPES.PHONE_CALL]: 'calls',
  [COMMUNICATION_TYPES.COMMUNICATION_CHAIN]: 'calls',
  [COMMUNICATION_TYPES.CUSTOM]: 'review',
  empty: ''
};

const ClientInteractionLink = ({
  id,
  isReview,
  communication,
  useDrawer = true,
  checklistDefinitionId,
  link = null,
  style = {},
  showIcon = true,
  showChild = false,
  children = null,
  type = null,
  chain = false
}) => {
  const dispatch = useDispatch();
  const { communicationType, clientInteractionType } = communication;
  const visitedInteractions = useSelector(
    state => state.uiClientInteractionDrawer.visitedInteractions
  );
  const visited = visitedInteractions.includes(id);
  const Icon =
    clientInteractionType === COMMUNICATION_TYPES.CUSTOM
      ? typeToIcon[COMMUNICATION_TYPES.CUSTOM]
      : typeToIcon[communicationType || clientInteractionType || COMMUNICATION_TYPES.PHONE_CALL];

  const query = checklistDefinitionId ? `checklistDefinitionId=${checklistDefinitionId}` : '';
  let navigationLink;

  const onLinkClickNewTab = e => {
    window.open(navigationLink, '_blank');
    return;
  };

  const removeQuestionMark = str => {
    if (!str) {
      return;
    }
    if (str.endsWith('?')) {
      return str.slice(0, -1);
    }

    return str;
  };

  const onLinkClick = useCallback(
    e => {
      localStorage.removeItem('totResult__');
      localStorage.removeItem('formulas_');
      if (!e.ctrlKey && !e.metaKey && useDrawer) {
        e.preventDefault();
        if (type === 'phone_calls' || type === 'text-communications') {
          dispatch(
            setCommunication({
              type:
                communication.communicationType === 'phone_call' ? 'calls' : 'text_communications',
              id
            })
          );
        } else {
          dispatch(setCommunication({ type: isReview ? 'review' : clientInteractionType, id }));
        }
      }
      dispatch(visitInteraction(id));
    },
    [dispatch, isReview, useDrawer, id]
  );

  if ((!!type && !chain) || communication.communicationType === 'communication_chain') {
    if (communication.communicationType === 'communication_chain' && !isReview) {
      navigationLink = `/communication-chains/${id}`;
    } else if (communication.communicationType === 'communication_chain') {
      navigationLink =
        link || `/${isReview ? 'reviews' : typeToRoute[clientInteractionType] || 'reviews'}/${id}`;
    } else {
      navigationLink = `/${
        communication.communicationType === 'phone_call' ? 'calls' : 'text-communications'
      }/${id}?${query}`;
    }
  } else {
    navigationLink =
      link || `/${isReview ? 'reviews' : typeToRoute[clientInteractionType] || 'reviews'}/${id}`;
  }
  navigationLink = removeQuestionMark(navigationLink);

  return (
    <StyledLink
      to={type || chain ? null : navigationLink}
      onClick={type || chain ? onLinkClickNewTab : onLinkClick}
      visited={visited ? 'true' : undefined}
      style={style}
    >
      {showIcon && <Icon />}
      {showChild && children}
    </StyledLink>
  );
};

export default ClientInteractionLink;
